import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-dark-purple-p px-5 pt-3 pb-5 absolute top-7 right-0 flex flex-col items-center rounded-b-lg rounded-tl-lg drop-shadow-2xl space-y-4 z-40" }
const _hoisted_2 = { class: "flex flex-row space-x-4 justify-center items-center" }
const _hoisted_3 = { class: "text-xl font-semibold text-white" }
const _hoisted_4 = { class: "text-gray-300" }
const _hoisted_5 = { class: "flex flex-col items-stretch w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_button_list_item = _resolveComponent("button-list-item")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("section", null, [
        _createElementVNode("i", { class: "fas fa-user-circle fa-3x text-white" })
      ], -1)),
      _createElementVNode("section", null, [
        _createElementVNode("h1", _hoisted_3, _toDisplayString(this.$store.state.user.currentUser.username), 1),
        _createElementVNode("h3", _hoisted_4, _toDisplayString(this.$store.state.user.currentUser.email), 1)
      ])
    ]),
    _createElementVNode("section", _hoisted_5, [
      _createVNode(_component_button_list_item, { onClick: _ctx.openAccountSettings }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createTextVNode("Account settings")
        ])),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_button_list_item, { onClick: _ctx.openMyDrops }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [
          _createTextVNode("My drops")
        ])),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_button_list_item, {
        danger: true,
        onClick: _ctx.signOut
      }, {
        default: _withCtx(() => _cache[3] || (_cache[3] = [
          _createTextVNode("Sign out")
        ])),
        _: 1
      }, 8, ["onClick"])
    ])
  ]))
}