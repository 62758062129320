import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex flex-row gap-x-2 items-end items" }
const _hoisted_2 = {
  key: 0,
  class: "flex flex-row items-stretch h-10"
}
const _hoisted_3 = {
  key: 1,
  class: "flex flex-row gap-x-2 items-stretch h-10"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_titled_filled_field = _resolveComponent("titled-filled-field")!
  const _component_basic_button = _resolveComponent("basic-button")!
  const _component_primary_button = _resolveComponent("primary-button")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_titled_filled_field, {
      title: _ctx.$props.title,
      modelValue: _ctx.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
      disable: !_ctx.editing
    }, null, 8, ["title", "modelValue", "disable"]),
    (!_ctx.$props.editing)
      ? (_openBlock(), _createElementBlock("section", _hoisted_2, [
          _createVNode(_component_basic_button, {
            class: "py-2 mb-0.5",
            onClick: _ctx.edit
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode("Edit")
            ])),
            _: 1
          }, 8, ["onClick"])
        ]))
      : (_openBlock(), _createElementBlock("section", _hoisted_3, [
          _createVNode(_component_primary_button, {
            class: "py-2 mb-0.5",
            onClick: _ctx.save
          }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode("Save")
            ])),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_basic_button, {
            class: "py-2 mb-0.5",
            onClick: _ctx.cancel
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createTextVNode("Cancel")
            ])),
            _: 1
          }, 8, ["onClick"])
        ]))
  ]))
}