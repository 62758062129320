import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex-grow w-full" }
const _hoisted_2 = { class: "flex flex-row justify-center h-5/6 w-full bg-dark-purple-p mt-10 px-8 pt-2 gap-8" }
const _hoisted_3 = {
  key: 0,
  class: "flex flex-col mt-4 w-full items-start px-3 xl:w-[72rem]"
}
const _hoisted_4 = { class: "flex flex-col gap-3 mt-4" }
const _hoisted_5 = {
  key: 1,
  class: "flex flex-col mt-4 w-full items-start px-3 xl:w-[72rem]"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_titled_filled_field = _resolveComponent("titled-filled-field")!
  const _component_primary_button = _resolveComponent("primary-button")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      (!_ctx.success)
        ? (_openBlock(), _createElementBlock("section", _hoisted_3, [
            _cache[3] || (_cache[3] = _createElementVNode("h1", { class: "font-inter font-bold text-white text-3xl" }, "Reset password", -1)),
            _cache[4] || (_cache[4] = _createElementVNode("h3", { class: "text-gray-300 mt-0.5" }, "Enter a new password for your account", -1)),
            _createElementVNode("section", _hoisted_4, [
              _createVNode(_component_titled_filled_field, {
                title: "Password",
                "field-type": "password",
                placeholder: "Password",
                modelValue: _ctx.password,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.password) = $event))
              }, null, 8, ["modelValue"]),
              _createVNode(_component_titled_filled_field, {
                title: "Confirm password",
                "field-type": "password",
                placeholder: "Confirm password",
                modelValue: _ctx.verifyPassword,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.verifyPassword) = $event))
              }, null, 8, ["modelValue"]),
              _createVNode(_component_primary_button, {
                class: "py-2 mt-4",
                onClick: _ctx.resetPassword
              }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createTextVNode("Reset password")
                ])),
                _: 1
              }, 8, ["onClick"])
            ])
          ]))
        : (_openBlock(), _createElementBlock("section", _hoisted_5, _cache[5] || (_cache[5] = [
            _createElementVNode("h1", { class: "font-inter font-bold text-white text-3xl" }, "Password reset successfully", -1),
            _createElementVNode("h3", { class: "text-gray-300 mt-0.5" }, "Please log in with your new password", -1)
          ])))
    ])
  ]))
}