import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex-grow" }
const _hoisted_2 = { class: "flex flex-col relative w-full h-5/6 justify-center items-center" }
const _hoisted_3 = { class: "flex flex-col space-y-4 pt-20" }
const _hoisted_4 = {
  key: 0,
  class: "absolute top-0 left-0 overflow-hidden w-full h-full mt-10"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_drop_container = _resolveComponent("drop-container")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[2] || (_cache[2] = _createElementVNode("p", { class: "font-inter font-light text-xl text-center text-white" }, "Drop files here", -1)),
        _cache[3] || (_cache[3] = _createElementVNode("p", { class: "font-inter font-light text-xl text-center text-white" }, "or", -1)),
        _createElementVNode("button", {
          class: "block font-inter font-light text-xl text-white text-center px-5 py-3 mx-auto border border-gray-100 hover:border-white hover:bg-white hover:text-black transition-colors",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openFileExplorer && _ctx.openFileExplorer(...args)))
        }, "Choose from file explorer"),
        _createElementVNode("input", {
          type: "file",
          class: "hidden",
          ref: "fileInput",
          onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onFileChange && _ctx.onFileChange(...args))),
          multiple: ""
        }, null, 544)
      ]),
      _createVNode(_Transition, { name: "height" }, {
        default: _withCtx(() => [
          (_ctx.isViewingDrop)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_drop_container)
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ])
  ]))
}