<template>
  <Dialog :visible="previewState.kind !== 'hidden'" @close="$store.dispatch('hideFilePreview')">
    <section class="flex flex-col w-full h-full px-3 pt-2 pb-4"
             v-if="previewState.kind === 'loaded'">
      <h1 class="font-inter text-gray-50 font-semibold text-2xl">Preview</h1>
      <h3 class="font-inter text-gray-500">{{ previewState.filename }}</h3>
      <textarea class="flex-grow w-full text-sm lg:text-base rounded mt-4 font-jb
        text-[#abb2bf] bg-[#282c34] border-dark-purple-l outline-none px-1 py-1
        selection:bg-slate-100/10" v-model="previewState.content" :disabled="true"/>
    </section>
    <section class="flex flex-col justify-center items-center w-full h-full space-y-3"
             v-else-if="previewState.kind === 'error'">
      <i class="fas fa-exclamation-triangle fa-3x text-white"></i>
      <h6 class="text-gray-200">
        {{ previewState.message ?? 'Error occurred while loading preview' }}</h6>
    </section>
    <section class="flex flex-col justify-center items-center h-full w-full space-y-3" v-else>
      <progress-spinner :size="10"/>
      <h6 class="text-gray-200">Loading preview</h6>
    </section>
  </Dialog>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { FilePreviewState } from '@/interfaces/store/drop';
import ProgressSpinner from '@/ui/components/core/progress_bars/progress_spinner.vue';
import Dialog from '@/ui/components/core/dialogs/dialog.vue';

export default defineComponent({
  name: 'FilePreviewDialog',
  components: {
    Dialog,
    ProgressSpinner,
  },
  computed: {
    previewState(): FilePreviewState {
      return this.$store.state.drop.previewState;
    },
  },
});
</script>

<style scoped>

</style>
