<template>
  <nav class="flex flex-col mt-4">
    <h1 class="font-inter font-bold text-white text-3xl px-3">Profile</h1>
    <ul class="flex flex-col gap-y-1 mt-5 items-stretch w-full lg:w-56">
      <profile-sidebar-item
        link="/profile/account" :selected="currentRouteName === 'accountSettings'"
      >Account settings</profile-sidebar-item>
      <profile-sidebar-item
        link="/profile/drops" :selected="currentRouteName === 'myDrops'"
      >My drops</profile-sidebar-item>
    </ul>
  </nav>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ProfileSidebarItem from '@/ui/components/profile/sidebar/profile_sidebar_item.vue';

export default defineComponent({
  name: 'ProfileSidebar',
  components: { ProfileSidebarItem },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
});
</script>
