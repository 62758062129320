<template>
  <li class="flex flex-row w-full justify-between border-t border-b border-dark-purple-xl
              border-collapse py-3">
    <section class="flex flex-col transition-all">
      <h2 class="font-inter text-lg font-medium text-white p-0 m-0 leading-5">{{ dropId }}</h2>
      <p class="font-inter text-sm lg:text-base text-gray-300 p-0 m-0 align-text-top
                  leading-5">Created on: {{ createDate.toLocaleString('en-uk') }}</p>
    </section>
    <section class="flex flex-row py-0.5 gap-2">
      <primary-button-link :to="link">View</primary-button-link>
      <basic-button-red @click="deleteDrop">Delete</basic-button-red>
    </section>
  </li>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import PrimaryButtonLink from '@/ui/components/core/links/primary_button_link.vue';
import BasicButtonRed from '@/ui/components/core/buttons/basic_button_red.vue';

export default defineComponent({
  name: 'MyDropListItem',
  components: { BasicButtonRed, PrimaryButtonLink },
  props: {
    dropId: String,
    createDate: Date,
    link: String,
  },
  methods: {
    deleteDrop() {
      this.$store.dispatch('deleteFromMyDrops', this.dropId);
    },
  },
});
</script>

<style scoped>

</style>
