import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex-grow w-full" }
const _hoisted_2 = { class: "flex flex-col lg:flex-row justify-center h-5/6 w-full bg-dark-purple-p mt-10 px-2 lg:px-8 pt-2 gap-2 lg:gap-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_profile_sidebar = _resolveComponent("profile-sidebar")!
  const _component_profile_section_container = _resolveComponent("profile-section-container")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _createVNode(_component_profile_sidebar),
      _createVNode(_component_profile_section_container)
    ])
  ]))
}