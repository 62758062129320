import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-y-5" }
const _hoisted_2 = { class: "flex flex-col gap-y-2" }
const _hoisted_3 = { class: "flex flex-row items-end gap-x-16" }
const _hoisted_4 = { class: "h-10 flex flex-row items-stretch" }
const _hoisted_5 = { class: "flex flex-row items-end gap-x-4" }
const _hoisted_6 = { class: "h-10 flex flex-row items-stretch" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_account_edit_field = _resolveComponent("account-edit-field")!
  const _component_profile_settings_section = _resolveComponent("profile-settings-section")!
  const _component_basic_button = _resolveComponent("basic-button")!
  const _component_basic_button_red = _resolveComponent("basic-button-red")!
  const _component_confirm_dialog = _resolveComponent("confirm-dialog")!
  const _component_profile_section = _resolveComponent("profile-section")!

  return (_openBlock(), _createBlock(_component_profile_section, {
    title: "Account settings",
    subtitle: "Manage account settings"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_profile_settings_section, { title: "User info" }, {
          default: _withCtx(() => [
            _createVNode(_component_account_edit_field, {
              title: "Username",
              modelValue: _ctx.username,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.username) = $event)),
              editing: _ctx.editingField === 1,
              onEdit: _ctx.editUsername,
              onCancel: _ctx.cancelEdit,
              onSave: _ctx.saveUsername
            }, null, 8, ["modelValue", "editing", "onEdit", "onCancel", "onSave"]),
            _createVNode(_component_account_edit_field, {
              title: "Email",
              modelValue: _ctx.email,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.email) = $event)),
              editing: _ctx.editingField === 2,
              onEdit: _ctx.editEmail,
              onCancel: _ctx.cancelEdit,
              onSave: _ctx.saveEmail
            }, null, 8, ["modelValue", "editing", "onEdit", "onCancel", "onSave"])
          ]),
          _: 1
        }),
        _createVNode(_component_profile_settings_section, { title: "User actions" }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("section", _hoisted_3, [
                _cache[3] || (_cache[3] = _createElementVNode("section", null, [
                  _createElementVNode("h5", { class: "text-white" }, "Reset password"),
                  _createElementVNode("p", { class: "text-gray-300" }, "Send reset link with email")
                ], -1)),
                _createElementVNode("section", _hoisted_4, [
                  _createVNode(_component_basic_button, {
                    class: "py-2 mb-0.5 h-12 lg:h-auto w-28 lg:w-auto",
                    onClick: _ctx.createResetPasswordLink
                  }, {
                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                      _createTextVNode("Reset password ")
                    ])),
                    _: 1
                  }, 8, ["onClick"])
                ])
              ]),
              _createElementVNode("section", _hoisted_5, [
                _cache[5] || (_cache[5] = _createElementVNode("section", null, [
                  _createElementVNode("h5", { class: "text-white" }, "Delete account"),
                  _createElementVNode("p", { class: "text-gray-300" }, "Delete all user data and settings")
                ], -1)),
                _createElementVNode("section", _hoisted_6, [
                  _createVNode(_component_basic_button_red, {
                    class: "py-2 mb-0.5",
                    onClick: _ctx.showDeleteAccountConfirm
                  }, {
                    default: _withCtx(() => _cache[4] || (_cache[4] = [
                      _createTextVNode("Delete ")
                    ])),
                    _: 1
                  }, 8, ["onClick"])
                ])
              ])
            ])
          ]),
          _: 1
        })
      ]),
      _createVNode(_component_confirm_dialog, {
        title: "Confirm",
        text: "Are you sure you want to delete your account?\n                    This will erase all user data as well as all your drops.",
        destructive: true,
        "confirm-title": "Delete",
        onCancelConfirm: _ctx.cancelDeleteAccount,
        onConfirm: _ctx.deleteAccount,
        visible: _ctx.confirmDeleteAccountVisible
      }, null, 8, ["onCancelConfirm", "onConfirm", "visible"])
    ]),
    _: 1
  }))
}