<template>
  <li :class="`flex flex-row items-center gap-x-2 font-medium text-white px-3 py-2
             border-b border-b-dark-purple-s last:border-none hover:cursor-pointer
             hover:${containerHoverColor} active:${containerActiveColor}`">
    <div class="flex flex-row justify-center items-center w-7 h-7">
      <i :class="`${icon}`"></i>
    </div>
    <slot class="h-7" />
  </li>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ContextMenuItem',
  props: {
    icon: String,
    danger: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    containerHoverColor() {
      if (this.$props.danger) return 'bg-red-800';
      return 'bg-violet-800';
    },
    containerActiveColor() {
      if (this.$props.danger) return 'bg-red-900';
      return 'bg-violet-900';
    },
  },
});
</script>

<style scoped>

</style>
