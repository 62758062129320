<template>
  <main class="flex-grow w-full">
    <section
      class="flex flex-col lg:flex-row justify-center h-5/6 w-full bg-dark-purple-p mt-10 px-2
             lg:px-8 pt-2 gap-2 lg:gap-8">
      <profile-sidebar/>
      <profile-section-container/>
    </section>
  </main>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ProfileSidebar from '@/ui/components/profile/sidebar/profile_sidebar.vue';
import ProfileSectionContainer from '@/ui/components/profile/profile_section_container.vue';

export default defineComponent({
  name: 'Profile',
  components: {
    ProfileSectionContainer,
    ProfileSidebar,
  },
});
</script>
