<template>
  <div class="bg-dark-purple-p w-72 flex flex-row gap-x-3 px-4 py-3 rounded drop-shadow-xl">
    <section>
      <i :class="`${icon} fa-lg ${primaryTextColor}`"></i>
    </section>
    <section class="flex-grow">
      <h1 :class="primaryTextColor">{{ $props.notification.title }}</h1>
      <p class="text-gray-400">{{ $props.notification.content }}</p>
    </section>
    <section>
      <button @click="closeNotification">
        <i class="fas fa-times text-gray-500 hover:text-gray-400"></i>
      </button>
    </section>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { Notification, NotificationType } from '@/interfaces/store/notifications';

export default defineComponent({
  name: 'Notification',
  props: {
    notification: Object as PropType<Notification>,
  },
  computed: {
    notificationType() {
      return this.$props.notification?.type ?? NotificationType.Info;
    },
    primaryTextColor() {
      switch (this.notificationType) {
        case NotificationType.Info: return 'text-violet-500';
        case NotificationType.Success: return 'text-green-500';
        case NotificationType.Warning: return 'text-orange-500';
        case NotificationType.Error: return 'text-red-500';
        default: return 'text-violet-500';
      }
    },
    icon() {
      switch (this.notificationType) {
        case NotificationType.Info: return 'fas fa-info-circle';
        case NotificationType.Success: return 'fas fa-check-circle';
        case NotificationType.Warning: return 'fas fa-exclamation-triangle';
        case NotificationType.Error: return 'fas fa-times-circle';
        default: return 'fas fa-info-circle';
      }
    },
  },
  methods: {
    closeNotification() {
      if (this.$props.notification === undefined) {
        throw Error('Tried to remove invalid notification');
      }

      this.$store.dispatch('removeNotificationById', this.$props.notification.id);
    },
  },
});
</script>

<style scoped>

</style>
