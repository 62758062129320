import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["placeholder", "value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("input", {
    class: "bg-transparent border border-dark-purple-xl text-white px-2 py-2 rounded w-40 lg:w-auto text-sm lg:text-base",
    placeholder: _ctx.placeholder,
    value: _ctx.value,
    ref: "input"
  }, null, 8, _hoisted_1))
}