import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-row justify-center items-center fixed left-0 top-0 w-screen h-screen bg-black/40 z-20"
}
const _hoisted_2 = {
  class: "flex flex-col w-[90vw] h-[80vh] lg:w-[48rem] lg:h-[40rem] rounded-lg bg-dark-purple-p drop-shadow-lg",
  id: "dialog"
}
const _hoisted_3 = { class: "absolute block right-0 top-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, { name: "upscale" }, {
    default: _withCtx(() => [
      (_ctx.visible)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("section", _hoisted_3, [
                _createElementVNode("button", {
                  class: "text-red-900 border border-red-900/30 hover:bg-red-900/20 rounded-full w-10 h-10 mx-2 my-2 text-lg",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
                }, _cache[1] || (_cache[1] = [
                  _createElementVNode("i", { class: "fas fa-times" }, null, -1)
                ]))
              ]),
              _renderSlot(_ctx.$slots, "default", {}, undefined, true)
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }))
}