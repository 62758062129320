import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col mt-4" }
const _hoisted_2 = { class: "flex flex-col gap-y-1 mt-5 items-stretch w-full lg:w-56" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_profile_sidebar_item = _resolveComponent("profile-sidebar-item")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _cache[2] || (_cache[2] = _createElementVNode("h1", { class: "font-inter font-bold text-white text-3xl px-3" }, "Profile", -1)),
    _createElementVNode("ul", _hoisted_2, [
      _createVNode(_component_profile_sidebar_item, {
        link: "/profile/account",
        selected: _ctx.currentRouteName === 'accountSettings'
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode("Account settings")
        ])),
        _: 1
      }, 8, ["selected"]),
      _createVNode(_component_profile_sidebar_item, {
        link: "/profile/drops",
        selected: _ctx.currentRouteName === 'myDrops'
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createTextVNode("My drops")
        ])),
        _: 1
      }, 8, ["selected"])
    ])
  ]))
}