<template>
  <ul class="fixed top-10 right-10 z-50 flex flex-col gap-y-3">
    <transition-group name="notification-list">
      <notification v-for="n of $store.state.notifications.notifications" :notification="n"
                    :key="n.id" />
    </transition-group>
  </ul>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Notification from '@/ui/components/core/notifications/Notification.vue';

export default defineComponent({
  name: 'NotificationContainer',
  components: { Notification },
});
</script>

<style scoped>
.notification-list-move,
.notification-list-enter-active,
.notification-list-leave-active {
  transition: all 0.4s ease;
}
.notification-list-leave-active {
  position: absolute;
}
.notification-list-enter-from,
.notification-list-leave-to {
  @apply opacity-0 transform translate-x-60;
}
</style>
