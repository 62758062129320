<template>
  <input class="bg-transparent border border-dark-purple-xl text-white px-2 py-2 rounded
                w-40 lg:w-auto text-sm lg:text-base"
         :placeholder="placeholder" :value="value" ref="input" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'text-field',
  props: {
    placeholder: String,
    value: String,
  },
  methods: {
    selectText() {
      const input = this.$refs.input as HTMLInputElement;
      input.select();
    },
    selectAndCopy() {
      this.selectText();
      document.execCommand('copy');
    },
  },
});
</script>

<style scoped>
input::selection {
  background-color: #311b92;
  color: white;
}
</style>
