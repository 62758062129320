<template>
  <input v-model="value" type="text" :class="style" :disabled="disable" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'FilledTextField',
  props: {
    modelValue: String,
    disable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    style() {
      if (this.$props.disable) {
        return `bg-dark-purple-l bg-opacity-50 text-gray-500 placeholder-gray-500 px-3 py-2
                text-white rounded outline-none w-56`;
      }

      return `bg-dark-purple-s px-3 py-2 text-white rounded outline-none focus:bg-opacity-60
              focus:outline-2 focus:outline-indigo-800 transition-all w-56`;
    },
  },
  data() {
    return {
      value: this.$props.modelValue,
    };
  },
  watch: {
    modelValue(newValue: string, oldValue: string) {
      this.value = newValue;
    },
    value(newValue: string, oldValue: string) {
      this.$emit('update:modelValue', newValue);
    },
  },
});
</script>

<style scoped>

</style>
