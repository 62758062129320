<template>
<section class="flex flex-row">
  <section class="w-32 lg:w-1/3">
    <h3 class="text-gray-300">{{ $props.title }}</h3>
  </section>
  <section class="w-2/3 flex flex-col gap-y-3">
    <slot />
  </section>
</section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ProfileSettingsSection',
  props: {
    title: String,
  },
});
</script>
