<template>
  <button class="border border-dark-purple-xl text-white px-4 py-1 rounded transition-colors
    font-medium hover:bg-violet-800 active:bg-violet-900 focus:outline-none ring-transparent
    leading-5">
    <slot />
  </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'basic-button',
  props: {

  },
});
</script>

<style scoped>

</style>
