import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-white px-2 mx-2 py-2 cursor-default bg-violet-700 rounded-lg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.$props.selected)
    ? (_openBlock(), _createElementBlock("li", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default")
      ]))
    : (_openBlock(), _createElementBlock("li", {
        key: 1,
        class: "text-gray-300 px-4 py-2 hover:text-gray-400 cursor-pointer",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
      }, [
        _renderSlot(_ctx.$slots, "default")
      ]))
}