<template>
  <button :disabled="disable" :class="style">
    <slot />
  </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LargeButton',
  props: {
    disable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    style() {
      if (this.$props.disable) {
        return `bg-dark-purple-l bg-opacity-50 text-gray-500 px-6 py-2 rounded transition-colors
        font-medium focus:outline-none ring-transparent leading-5`;
      }

      return `text-white px-6 py-2 rounded transition-colors border border-dark-purple-xl
      font-medium hover:bg-violet-800 active:bg-violet-900 focus:outline-none ring-transparent leading-5`;
    },
  },
});
</script>
