<template>
  <main class="flex-grow w-full">
    <section
      class="flex flex-row justify-center h-5/6 w-full bg-dark-purple-p mt-10 px-8 pt-2 gap-8">
      <section class="flex flex-col mt-4 w-full items-start px-3 xl:w-[72rem]" v-if="!success">
        <h1 class="font-inter font-bold text-white text-3xl">Reset password</h1>
        <h3 class="text-gray-300 mt-0.5">Enter a new password for your account</h3>

        <section class="flex flex-col gap-3 mt-4">
          <titled-filled-field title="Password" field-type="password" placeholder="Password"
                               v-model="password"/>
          <titled-filled-field title="Confirm password" field-type="password"
                               placeholder="Confirm password"
                               v-model="verifyPassword"/>
          <primary-button class="py-2 mt-4" @click="resetPassword">Reset password</primary-button>
        </section>
      </section>
      <section class="flex flex-col mt-4 w-full items-start px-3 xl:w-[72rem]" v-else>
        <h1 class="font-inter font-bold text-white text-3xl">Password reset successfully</h1>
        <h3 class="text-gray-300 mt-0.5">Please log in with your new password</h3>
      </section>
    </section>
  </main>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import TitledFilledField from '@/ui/components/core/text_fields/titled_filled_field.vue';
import PrimaryButton from '@/ui/components/core/buttons/primary_button.vue';
import { Api } from '@/services';
import { BasicError } from '@/interfaces/models';
import Axios, { AxiosError } from 'axios';
import { Router } from 'vue-router';

export default defineComponent({
  name: 'reset_password',
  components: {
    PrimaryButton,
    TitledFilledField,
  },
  data() {
    return {
      password: '',
      verifyPassword: '',
      token: this.$route.params.token as string,
      success: false,
    };
  },
  async mounted() {
    try {
      await Api.getResetPasswordLink(this.token);
    } catch (e) {
      if (Axios.isAxiosError(e)) {
        if (e?.response?.status === 404) {
          this.$store.dispatch('pushErrorNotification', 'This link is not valid. Please create a new one and try again');
        } else {
          this.$store.dispatch('pushErrorNotification', 'Unknown error occurred while verifying link. Please create a new one or try again later');
        }
        await this.$router.push('/');
      } else {
        this.$store.dispatch('pushErrorNotification', 'Unknown error occurred.');
      }
    }
  },
  methods: {
    resetPassword() {
      if (this.password === '') {
        this.$store.dispatch('pushErrorNotification', 'Password cannot be empty');
        return;
      }
      if (this.password !== this.verifyPassword) {
        this.$store.dispatch('pushErrorNotification', 'Password do not match');
        return;
      }

      try {
        Api.resetPassword({
          token: this.token,
          password: this.password,
          verifyPassword: this.verifyPassword,
        });
        this.$store.dispatch('pushSuccessNotification', 'Password has been reset successfully');
        this.success = true;
      } catch (e) {
        if ((e as AxiosError | null)?.response?.data !== undefined) {
          const response = (e as AxiosError).response!.data as BasicError;
          this.$store.dispatch('pushErrorNotification', `Failed to reset password. ${response.reason}`);
        } else {
          this.$store.dispatch('pushErrorNotification', 'Unknown error occurred while resetting password. Please try again later.');
        }
      }
    },
  },
});
</script>

<style scoped>

</style>
