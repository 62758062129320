<template>
<div class="w-full h-full bg-dark-purple-s px-2 lg:px-12 py-4">
  <div class="flex flex-col" v-if="isDropLoaded">
    <drop-header :drop-id="drop.id" />
    <section class="px-1 mt-2">
      <drop-file-list :files="files" />
    </section>
    <file-preview-dialog />
  </div>
  <div class="flex flex-col justify-center items-center h-full w-full space-y-3"
       v-else-if="dropError.isError">
    <i class="fas fa-exclamation-triangle fa-3x text-white"></i>
    <h6 class="text-gray-200">{{ dropError.message ?? 'Error occurred while loading drop' }}</h6>
    <large-button @click="$router.push('/')">Go to homepage</large-button>
  </div>
  <div class="flex flex-col justify-center items-center h-full w-full space-y-3" v-else>
    <progress-spinner :size="10" />
    <h6 class="text-gray-200">Loading drop</h6>
  </div>
</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Drop } from '@/interfaces/models/drop';
import DropHeader from '@/ui/components/core/headers/drop_header.vue';
import DropFileList from '@/ui/components/core/lists/drop_file_list.vue';
import { AnyDropFile } from '@/interfaces/store/drop';
import ProgressSpinner from '@/ui/components/core/progress_bars/progress_spinner.vue';
import { ErrorState } from '@/interfaces/store/shared';
import LargeButton from '@/ui/components/core/buttons/large_button.vue';
import FilePreviewDialog from '@/ui/components/drop/file_preview_dialog.vue';

export default defineComponent({
  name: 'drop-container',
  components: {
    FilePreviewDialog,
    LargeButton,
    ProgressSpinner,
    DropFileList,
    DropHeader,
  },
  computed: {
    isDropLoaded(): boolean {
      return this.$store.state.drop.drop !== null;
    },
    drop(): Drop {
      return this.$store.state.drop.drop;
    },
    files(): AnyDropFile[] {
      return this.$store.state.drop.dropFiles;
    },
    dropError(): ErrorState {
      return this.$store.state.drop.error;
    },
  },
});

</script>

<style scoped>

</style>
