import { createStore } from 'vuex';
import drop from '@/store/drop';
import user from '@/store/user';
import notifications from '@/store/notifications';
import myDrops from '@/store/my_drops';
import axios, { AxiosRequestConfig } from 'axios';
import { Api } from '@/services';

const store = createStore({
  modules: {
    drop,
    user,
    notifications,
    myDrops,
  },
});

setInterval(() => store.dispatch('updateNotifications'), 100);

// eslint-disable-next-line @typescript-eslint/no-empty-function
axios.interceptors.response.use((response) => response, (error) => {
  if (axios.isAxiosError(error)) {
    if (error.response?.status === 401 && store.state.user.refreshToken !== null) {
      return Api.refreshTokens(store.state.user.refreshToken).then((res) => {
        store.commit('setTokens', res.data);
        // eslint-disable-next-line no-param-reassign
        (error.config as AxiosRequestConfig).headers!.authorization = `Bearer ${store.state.user.token}`;

        return axios.request(error.config as AxiosRequestConfig);
      });
    }
  }

  return Promise.reject(error);
});

export default store;
