<template>
  <transition name="upscale-down">
    <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2
                bg-[#0E0E20] w-96 flex flex-col gap-y-5 rounded-lg px-8 pt-4 pb-4
                drop-shadow-lg" v-if="visible">
      <section class="flex flex-col gap-y-2">
        <h1 class="text-white font-bold text-2xl">{{ $props.title }}</h1>
        <p class="text-gray-300">{{ $props.text }}</p>
      </section>
      <section class="flex flex-row gap-x-4 justify-end h-10 items-stretch">
        <primary-button
          v-if="!$props.destructive" @click="confirm">{{ $props.confirmTitle }}</primary-button>
        <primary-button-red v-else @click="confirm">{{ $props.confirmTitle }}</primary-button-red>
        <basic-button @click="cancel">Cancel</basic-button>
      </section>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import PrimaryButton from '@/ui/components/core/buttons/primary_button.vue';
import BasicButton from '@/ui/components/core/buttons/basic_button.vue';
import PrimaryButtonRed from '@/ui/components/core/buttons/primary_button_red.vue';

export default defineComponent({
  name: 'ConfirmDialog',
  components: { PrimaryButtonRed, BasicButton, PrimaryButton },
  props: {
    title: String,
    text: String,
    destructive: {
      type: Boolean,
      default: false,
    },
    confirmTitle: {
      type: String,
      default: 'Confirm',
    },
    visible: Boolean,
  },
  methods: {
    confirm() {
      this.$emit('confirm');
    },
    cancel() {
      this.$emit('cancel-confirm');
    },
  },
});
</script>
