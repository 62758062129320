<template>
<li class="text-white px-2 mx-2 py-2 cursor-default bg-violet-700 rounded-lg"
    v-if="$props.selected"><slot /></li>
<li class="text-gray-300 px-4 py-2 hover:text-gray-400 cursor-pointer" @click="onClick"
    v-else><slot /></li>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ProfileSidebarItem',
  props: {
    selected: Boolean,
    link: String,
  },
  methods: {
    onClick() {
      if (this.$props.link === undefined) return;
      this.$router.push(this.$props.link);
    },
  },
});
</script>

<style scoped>

</style>
