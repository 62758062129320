<template>
  <div class="flex flex-col gap-y-1.5">
    <h4 class="text-gray-300">{{ $props.title }}</h4>
    <filled-text-field :type="fieldType" :placeholder="placeholder" v-model="value"
                       :disable="$props.disable"/>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import FilledTextField from '@/ui/components/core/text_fields/filled_text_field.vue';

export default defineComponent({
  name: 'TitledFilledField',
  components: { FilledTextField },
  props: {
    title: String,
    modelValue: String,
    placeholder: String,
    fieldType: String,
    disable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      value: this.$props.modelValue,
    };
  },
  watch: {
    modelValue(newValue: string, oldValue: string) {
      this.value = newValue;
    },
    value(newValue: string, oldValue: string) {
      this.$emit('update:modelValue', newValue);
    },
  },
});
</script>

<style scoped>

</style>
