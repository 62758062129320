<template>
  <button class="text-white px-6 py-2 rounded transition-colors border border-dark-purple-xl
     font-medium hover:bg-red-800 active:bg-red-900 focus:outline-none ring-transparent leading-5">
    <slot />
  </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LargeButtonRed',
  props: {

  },
});
</script>
