import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    href: _ctx.to,
    class: "bg-violet-700 text-white px-4 py-1 rounded transition-colors font-medium hover:bg-violet-800 active:bg-violet-900 focus:outline-none ring-transparent leading-7 flex flex-row items-center"
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 8, _hoisted_1))
}