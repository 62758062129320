<template>
  <main class="flex-grow">
    <section class="flex flex-col relative w-full h-5/6 justify-center items-center">
      <div class="flex flex-col space-y-4 pt-20">
        <p class="font-inter font-light text-xl text-center text-white">Drop files here</p>
        <p class="font-inter font-light text-xl text-center text-white">or</p>
        <button class="block font-inter font-light text-xl text-white text-center px-5 py-3
        mx-auto border border-gray-100 hover:border-white hover:bg-white hover:text-black
        transition-colors" @click="openFileExplorer">Choose from file explorer</button>
        <input type="file" class="hidden" ref="fileInput" @change="onFileChange" multiple>
      </div>
      <transition name="height">
        <div v-if="isViewingDrop"
             class="absolute top-0 left-0 overflow-hidden w-full h-full mt-10">
          <drop-container />
        </div>
      </transition>
    </section>
  </main>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import DropContainer from '@/ui/components/core/containers/drop_container.vue';

export default defineComponent({
  name: 'home',
  components: { DropContainer },
  mounted() {
    if ('dropId' in this.$route.params) {
      this.$store.dispatch('fetchDrop', this.$route.params.dropId);
    }
  },
  computed: {
    isViewingDrop(): boolean {
      return 'dropId' in this.$route.params;
    },
  },
  methods: {
    openFileExplorer() {
      const fileInput = this.$refs.fileInput as HTMLInputElement;
      fileInput.value = '';
      fileInput.click();
    },
    async onFileChange(e: Event) {
      if (!this.$store.getters.isDropActive) {
        await this.$store.dispatch('createDrop');
      }
      const { files } = (e.target as HTMLInputElement);
      this.$store.dispatch('uploadFiles', files);
    },
  },
});
</script>
