<template>
  <profile-section title="My drops" subtitle="All currently active drops">
    <div class="flex flex-col items-center gap-y-3 mt-10"
      v-if="$store.state.myDrops.error.isError">
      <i class="fas fa-exclamation-triangle fa-3x text-white"></i>
      <h6 class="text-gray-200">Failed to load my drops</h6>
      <large-button @click="loadDrops">Retry</large-button>
    </div>
    <div class="flex flex-col items-center gap-y-3 mt-10"
      v-else-if="$store.state.myDrops.drops === null">
      <progress-spinner :size="10" />
      <h6 class="text-gray-200">Loading my drops</h6>
    </div>
    <div class="flex flex-col items-center gap-y-3 mt-10"
         v-else-if="$store.state.myDrops.drops.length === 0">
      <h6 class="text-gray-200">You currently have no active drops</h6>
    </div>
    <ul class="flex flex-col" v-else>
      <my-drop-list-item v-for="drop of drops" :key="drop.id" :drop-id="drop.id"
                         :create-date="drop.createDate" :link="`/${drop.id}`" />
    </ul>
  </profile-section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ProfileSection from '@/ui/components/profile/profile_section.vue';
import MyDropListItem from '@/ui/components/profile/drops/my_drops_list_item.vue';
import LargeButton from '@/ui/components/core/buttons/large_button.vue';
import ProgressSpinner from '@/ui/components/core/progress_bars/progress_spinner.vue';
import { Drop } from '@/interfaces/models';

export default defineComponent({
  name: 'MyDropsSection',
  components: {
    ProgressSpinner,
    LargeButton,
    MyDropListItem,
    ProfileSection,
  },
  mounted() {
    this.loadDrops();
  },
  computed: {
    drops(): Drop[] {
      return this.$store.state.myDrops.drops;
    },
  },
  methods: {
    loadDrops() {
      this.$store.dispatch('loadMyDrops');
    },
  },
});
</script>

<style scoped>

</style>
