import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex-grow max-w-[72rem]"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_account_settings_section = _resolveComponent("account-settings-section")!
  const _component_my_drops_section = _resolveComponent("my-drops-section")!

  return (!_ctx.$store.getters.isLoggedIn)
    ? (_openBlock(), _createElementBlock("section", _hoisted_1, _cache[0] || (_cache[0] = [
        _createElementVNode("div", { class: "flex flex-col w-full items-center gap-y-3 mt-16" }, [
          _createElementVNode("i", { class: "fas fa-exclamation-triangle fa-3x text-white" }),
          _createElementVNode("h6", { class: "text-gray-200" }, "You have to sign in to access this page")
        ], -1)
      ])))
    : (_ctx.currentRouteName === 'accountSettings')
      ? (_openBlock(), _createBlock(_component_account_settings_section, { key: 1 }))
      : (_ctx.currentRouteName === 'myDrops')
        ? (_openBlock(), _createBlock(_component_my_drops_section, { key: 2 }))
        : _createCommentVNode("", true)
}