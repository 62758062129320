<template>
  <a :href="to" class="bg-violet-700 text-white px-4 py-1 rounded transition-colors font-medium
     hover:bg-violet-800 active:bg-violet-900 focus:outline-none ring-transparent leading-7
     flex flex-row items-center">
    <slot />
  </a>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PrimaryButtonLink',
  props: {
    to: String,
  },
});
</script>
