import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-col w-full h-full px-3 pt-2 pb-4"
}
const _hoisted_2 = { class: "font-inter text-gray-500" }
const _hoisted_3 = {
  key: 1,
  class: "flex flex-col justify-center items-center w-full h-full space-y-3"
}
const _hoisted_4 = { class: "text-gray-200" }
const _hoisted_5 = {
  key: 2,
  class: "flex flex-col justify-center items-center h-full w-full space-y-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_progress_spinner = _resolveComponent("progress-spinner")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: _ctx.previewState.kind !== 'hidden',
    onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$store.dispatch('hideFilePreview')))
  }, {
    default: _withCtx(() => [
      (_ctx.previewState.kind === 'loaded')
        ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
            _cache[2] || (_cache[2] = _createElementVNode("h1", { class: "font-inter text-gray-50 font-semibold text-2xl" }, "Preview", -1)),
            _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.previewState.filename), 1),
            _withDirectives(_createElementVNode("textarea", {
              class: "flex-grow w-full text-sm lg:text-base rounded mt-4 font-jb text-[#abb2bf] bg-[#282c34] border-dark-purple-l outline-none px-1 py-1 selection:bg-slate-100/10",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.previewState.content) = $event)),
              disabled: true
            }, null, 512), [
              [_vModelText, _ctx.previewState.content]
            ])
          ]))
        : (_ctx.previewState.kind === 'error')
          ? (_openBlock(), _createElementBlock("section", _hoisted_3, [
              _cache[3] || (_cache[3] = _createElementVNode("i", { class: "fas fa-exclamation-triangle fa-3x text-white" }, null, -1)),
              _createElementVNode("h6", _hoisted_4, _toDisplayString(_ctx.previewState.message ?? 'Error occurred while loading preview'), 1)
            ]))
          : (_openBlock(), _createElementBlock("section", _hoisted_5, [
              _createVNode(_component_progress_spinner, { size: 10 }),
              _cache[4] || (_cache[4] = _createElementVNode("h6", { class: "text-gray-200" }, "Loading preview", -1))
            ]))
    ]),
    _: 1
  }, 8, ["visible"]))
}