<template>
<header class="py-4">
  <div class="flex flex-row justify-between">
    <div class="flex flex-row items-stretch">
      <text-field @click="onLinkClick" ref="linkField" :value="link" />
      <basic-button @click="copyLink">Copy</basic-button>
    </div>
    <div class="flex flex-row items-stretch">
      <large-button-red @click="deleteDrop">Delete</large-button-red>
    </div>
  </div>
</header>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import TextField from '@/ui/components/core/text_fields/text_field.vue';
import BasicButton from '@/ui/components/core/buttons/basic_button.vue';
import LargeButtonRed from '@/ui/components/core/buttons/large_button_red.vue';

export default defineComponent({
  name: 'drop-header',
  components: {
    LargeButtonRed, BasicButton, TextField,
  },
  props: {
    dropId: String,
  },
  data() {
    return {
      hasSelectedLink: false,
    };
  },
  computed: {
    link() {
      return `${process.env.VUE_APP_URL}/${this.dropId}`;
    },
  },
  methods: {
    copyLink() {
      const linkField = this.$refs.linkField as typeof TextField;
      linkField.selectAndCopy();
      this.hasSelectedLink = true;
    },
    onLinkClick() {
      if (this.hasSelectedLink) return;

      const linkField = this.$refs.linkField as typeof TextField;
      linkField.selectText();
      this.hasSelectedLink = true;
    },
    deleteDrop() {
      this.$store.dispatch('deleteDrop');
    },
  },
});
</script>

<style scoped>

</style>
