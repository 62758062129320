<template>
  <div class="bg-dark-purple-p px-5 pt-3 pb-5 absolute top-7 right-0 flex flex-col items-center
              rounded-b-lg rounded-tl-lg drop-shadow-2xl space-y-4 z-40">
    <section class="flex flex-row space-x-4 justify-center items-center">
      <section>
        <i class="fas fa-user-circle fa-3x text-white"></i>
      </section>
      <section>
        <h1 class="text-xl font-semibold text-white">{{
            this.$store.state.user.currentUser.username
          }}</h1>
        <h3 class="text-gray-300">{{ this.$store.state.user.currentUser.email }}</h3>
      </section>
    </section>
    <section class="flex flex-col items-stretch w-full">
      <button-list-item @click="openAccountSettings">Account settings</button-list-item>
      <button-list-item @click="openMyDrops">My drops</button-list-item>
      <button-list-item :danger="true" @click="signOut">Sign out</button-list-item>
    </section>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ButtonListItem from '@/ui/components/core/list_items/button_list_item.vue';

export default defineComponent({
  name: 'UserDialog',
  components: { ButtonListItem },
  methods: {
    openAccountSettings() {
      this.$emit('optionSelect');
      this.$router.push('/profile/account');
    },
    openMyDrops() {
      this.$emit('optionSelect');
      this.$router.push('/profile/drops');
    },
    signOut() {
      this.$emit('optionSelect');
      this.$store.dispatch('logout');
    },
  },
});
</script>
