<template>
<div>
  <h1 class="text-white text-2xl">Sign in</h1>
  <section class="flex flex-col items-center space-y-2.5 mt-4">
    <filled-text-field v-model="username" :disable="disableControls" placeholder="Username" />
    <filled-text-field v-model="password" :disable="disableControls" placeholder="Password"
                       type="password" />
  </section>
  <section class="flex flex-row justify-between mt-6">
    <primary-large-button :disable="disableControls" @click="signIn">Sign in</primary-large-button>
    <large-button :disable="disableControls" @click="register">Register</large-button>
  </section>
  <section v-if="$store.state.user.error.isError">
    <p class="text-red-600 text-center py-3 text-sm">
      {{ $store.state.user.error.message }}
    </p>
  </section>
</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import FilledTextField from '@/ui/components/core/text_fields/filled_text_field.vue';
import PrimaryLargeButton from '@/ui/components/core/buttons/primary_large_button.vue';
import LargeButton from '@/ui/components/core/buttons/large_button.vue';

export default defineComponent({
  name: 'SignInDialogContent',
  components: { LargeButton, PrimaryLargeButton, FilledTextField },
  data() {
    return {
      username: '',
      password: '',
    };
  },
  computed: {
    disableControls() {
      return this.$store.state.user.isLoading;
    },
  },
  methods: {
    signIn() {
      this.$store.dispatch('authenticate', { username: this.username, password: this.password });
    },
    register() {
      this.$emit('register');
    },
  },
});
</script>

<style scoped>

</style>
