<template>
<header class="w-full h-10 flex flex-row justify-between">
  <div></div>
  <div class="relative mr-4 mt-1">
    <a :class="userButtonStyle" @click="onSignInClick">
      <i class="fas fa-user-circle mr-2"></i>
      {{ userButtonText }}
    </a>
    <transition name="opacity">
      <auth-dialog v-if="this.authDialogVisible && !this.isSignedIn" />
      <user-dialog v-else-if="this.authDialogVisible && this.isSignedIn"
                   @option-select="authDialogVisible = false" />
    </transition>
  </div>
</header>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import AuthDialog from '@/ui/components/core/dialogs/auth_dialog.vue';
import UserDialog from '@/ui/components/core/dialogs/user_dialog.vue';

export default defineComponent({
  name: 'main-header',
  components: { UserDialog, AuthDialog },
  data() {
    return {
      authDialogVisible: false,
    };
  },
  computed: {
    isSignedIn(): boolean {
      return this.$store.getters.isLoggedIn;
    },
    userButtonText(): string {
      return this.$store.getters.isLoggedIn
        ? this.$store.state.user.currentUser.username
        : 'Sign in';
    },
    userButtonStyle(): string {
      return this.authDialogVisible
        ? `font-inter font-light text-xl text-white px-2 py-1 cursor-default
          rounded-t transition-colors select-none bg-dark-purple-p`
        : `font-inter font-light text-xl text-white px-2 py-1 cursor-default
          rounded transition-colors button-bg-hover select-none`;
    },
  },
  methods: {
    onSignInClick() {
      this.authDialogVisible = !this.authDialogVisible;
    },
  },
});
</script>

<style scoped>
.button-bg-hover:hover {
  background-color: rgba(119, 118, 118, 0.22);
}

.opacity-enter-active,
.opacity-leave-active {
  @apply transition-opacity;
}
.opacity-enter-from,
.opacity-leave-to {
  @apply opacity-0;
}
</style>
