<template>
  <profile-section title="Account settings" subtitle="Manage account settings">
    <div class="flex flex-col gap-y-5">
      <profile-settings-section title="User info">
        <account-edit-field
          title="Username" v-model="username" :editing="editingField === 1"
          @edit="editUsername" @cancel="cancelEdit" @save="saveUsername"/>
        <account-edit-field
          title="Email" v-model="email" :editing="editingField === 2"
          @edit="editEmail" @cancel="cancelEdit" @save="saveEmail"/>
      </profile-settings-section>
      <profile-settings-section title="User actions">
        <div class="flex flex-col gap-y-2">
          <section class="flex flex-row items-end gap-x-16">
            <section>
              <h5 class="text-white">Reset password</h5>
              <p class="text-gray-300">Send reset link with email</p>
            </section>
            <section class="h-10 flex flex-row items-stretch">
              <basic-button class="py-2 mb-0.5 h-12 lg:h-auto w-28 lg:w-auto"
                            @click="createResetPasswordLink">Reset password
              </basic-button>
            </section>
          </section>
          <section class="flex flex-row items-end gap-x-4">
            <section>
              <h5 class="text-white">Delete account</h5>
              <p class="text-gray-300">Delete all user data and settings</p>
            </section>
            <section class="h-10 flex flex-row items-stretch">
              <basic-button-red
                class="py-2 mb-0.5" @click="showDeleteAccountConfirm">Delete
              </basic-button-red>
            </section>
          </section>
        </div>
      </profile-settings-section>
    </div>
    <!--eslint-disable-next-line-->
    <confirm-dialog title="Confirm"
                    text="Are you sure you want to delete your account?
                    This will erase all user data as well as all your drops."
                    :destructive="true" confirm-title="Delete" @cancel-confirm="cancelDeleteAccount"
                    @confirm="deleteAccount" :visible="confirmDeleteAccountVisible"/>
  </profile-section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ProfileSection from '@/ui/components/profile/profile_section.vue';
import ProfileSettingsSection from '@/ui/components/profile/profile_settings_section.vue';
import BasicButton from '@/ui/components/core/buttons/basic_button.vue';
import BasicButtonRed from '@/ui/components/core/buttons/basic_button_red.vue';
import AccountEditField from '@/ui/components/profile/account_settings/account_edit_field.vue';
import ConfirmDialog from '@/ui/components/core/dialogs/confirm_dialog.vue';
import { Api } from '@/services';

enum EditingField {
  None,
  Username,
  Email
}

export default defineComponent({
  name: 'AccountSettingsSection',
  components: {
    ConfirmDialog,
    AccountEditField,
    BasicButtonRed,
    BasicButton,
    ProfileSettingsSection,
    ProfileSection,
  },
  data() {
    return {
      username: this.$store.state.user.currentUser.username,
      email: this.$store.state.user.currentUser.email,
      editingField: EditingField.None as EditingField,
      confirmDeleteAccountVisible: false,
    };
  },
  computed: {
    currentUsername(): string {
      return this.$store.state.user.currentUser.username;
    },
    currentEmail(): string {
      return this.$store.state.user.currentUser.email;
    },
  },
  methods: {
    cancelCurrentEdit() {
      switch (this.editingField) {
        case EditingField.Username:
          this.username = this.currentUsername;
          break;
        case EditingField.Email:
          this.email = this.currentEmail;
          break;
        default:
          break;
      }
    },
    cancelEdit() {
      this.cancelCurrentEdit();
      this.editingField = EditingField.None;
    },
    editUsername() {
      this.cancelCurrentEdit();
      this.editingField = EditingField.Username;
    },
    editEmail() {
      this.cancelCurrentEdit();
      this.editingField = EditingField.Email;
    },
    async saveUsername() {
      await this.$store.dispatch('updateUsername', this.username);
      this.cancelCurrentEdit();
      this.editingField = EditingField.None;
    },
    async saveEmail() {
      await this.$store.dispatch('updateEmail', this.email);
      this.cancelCurrentEdit();
      this.editingField = EditingField.None;
    },
    showDeleteAccountConfirm() {
      this.confirmDeleteAccountVisible = true;
    },
    cancelDeleteAccount() {
      this.confirmDeleteAccountVisible = false;
    },
    deleteAccount() {
      this.$store.dispatch('deleteAccount');
    },
    async createResetPasswordLink() {
      try {
        await Api.createResetPasswordLink();
        this.$store.dispatch(
          'pushSuccessNotification',
          'Password reset link has been sent to your email',
        );
      } catch (e) {
        this.$store.dispatch(
          'pushErrorNotification',
          'Unexpected error occurred. Failed to send password reset link',
        );
      }
    },
  },
});
</script>
