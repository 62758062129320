import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "py-4" }
const _hoisted_2 = { class: "flex flex-row justify-between" }
const _hoisted_3 = { class: "flex flex-row items-stretch" }
const _hoisted_4 = { class: "flex flex-row items-stretch" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_field = _resolveComponent("text-field")!
  const _component_basic_button = _resolveComponent("basic-button")!
  const _component_large_button_red = _resolveComponent("large-button-red")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_text_field, {
          onClick: _ctx.onLinkClick,
          ref: "linkField",
          value: _ctx.link
        }, null, 8, ["onClick", "value"]),
        _createVNode(_component_basic_button, { onClick: _ctx.copyLink }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode("Copy")
          ])),
          _: 1
        }, 8, ["onClick"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_large_button_red, { onClick: _ctx.deleteDrop }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode("Delete")
          ])),
          _: 1
        }, 8, ["onClick"])
      ])
    ])
  ]))
}