import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full h-10 flex flex-row justify-between" }
const _hoisted_2 = { class: "relative mr-4 mt-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_auth_dialog = _resolveComponent("auth-dialog")!
  const _component_user_dialog = _resolveComponent("user-dialog")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _cache[3] || (_cache[3] = _createElementVNode("div", null, null, -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("a", {
        class: _normalizeClass(_ctx.userButtonStyle),
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onSignInClick && _ctx.onSignInClick(...args)))
      }, [
        _cache[2] || (_cache[2] = _createElementVNode("i", { class: "fas fa-user-circle mr-2" }, null, -1)),
        _createTextVNode(" " + _toDisplayString(_ctx.userButtonText), 1)
      ], 2),
      _createVNode(_Transition, { name: "opacity" }, {
        default: _withCtx(() => [
          (this.authDialogVisible && !this.isSignedIn)
            ? (_openBlock(), _createBlock(_component_auth_dialog, { key: 0 }))
            : (this.authDialogVisible && this.isSignedIn)
              ? (_openBlock(), _createBlock(_component_user_dialog, {
                  key: 1,
                  onOptionSelect: _cache[1] || (_cache[1] = ($event: any) => (_ctx.authDialogVisible = false))
                }))
              : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ])
  ]))
}