import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-col items-center gap-y-3 mt-10"
}
const _hoisted_2 = {
  key: 1,
  class: "flex flex-col items-center gap-y-3 mt-10"
}
const _hoisted_3 = {
  key: 2,
  class: "flex flex-col items-center gap-y-3 mt-10"
}
const _hoisted_4 = {
  key: 3,
  class: "flex flex-col"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_large_button = _resolveComponent("large-button")!
  const _component_progress_spinner = _resolveComponent("progress-spinner")!
  const _component_my_drop_list_item = _resolveComponent("my-drop-list-item")!
  const _component_profile_section = _resolveComponent("profile-section")!

  return (_openBlock(), _createBlock(_component_profile_section, {
    title: "My drops",
    subtitle: "All currently active drops"
  }, {
    default: _withCtx(() => [
      (_ctx.$store.state.myDrops.error.isError)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _cache[1] || (_cache[1] = _createElementVNode("i", { class: "fas fa-exclamation-triangle fa-3x text-white" }, null, -1)),
            _cache[2] || (_cache[2] = _createElementVNode("h6", { class: "text-gray-200" }, "Failed to load my drops", -1)),
            _createVNode(_component_large_button, { onClick: _ctx.loadDrops }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode("Retry")
              ])),
              _: 1
            }, 8, ["onClick"])
          ]))
        : (_ctx.$store.state.myDrops.drops === null)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_progress_spinner, { size: 10 }),
              _cache[3] || (_cache[3] = _createElementVNode("h6", { class: "text-gray-200" }, "Loading my drops", -1))
            ]))
          : (_ctx.$store.state.myDrops.drops.length === 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[4] || (_cache[4] = [
                _createElementVNode("h6", { class: "text-gray-200" }, "You currently have no active drops", -1)
              ])))
            : (_openBlock(), _createElementBlock("ul", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.drops, (drop) => {
                  return (_openBlock(), _createBlock(_component_my_drop_list_item, {
                    key: drop.id,
                    "drop-id": drop.id,
                    "create-date": drop.createDate,
                    link: `/${drop.id}`
                  }, null, 8, ["drop-id", "create-date", "link"]))
                }), 128))
              ]))
    ]),
    _: 1
  }))
}