<template>
  <main class="flex flex-col flex-grow max-w-[72rem]">
    <h1 class="text-white font-semibold text-lg mt-6">{{ $props.title }}</h1>
    <h3 class="text-gray-300">{{ $props.subtitle }}</h3>
    <section class="mt-4">
      <slot />
    </section>
  </main>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ProfileSection',
  props: {
    title: String,
    subtitle: String,
  },
});
</script>

<style scoped>

</style>
