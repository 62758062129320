import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, TransitionGroup as _TransitionGroup, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "fixed top-10 right-10 z-50 flex flex-col gap-y-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_notification = _resolveComponent("notification")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    _createVNode(_TransitionGroup, { name: "notification-list" }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$store.state.notifications.notifications, (n) => {
          return (_openBlock(), _createBlock(_component_notification, {
            notification: n,
            key: n.id
          }, null, 8, ["notification"]))
        }), 128))
      ]),
      _: 1
    })
  ]))
}