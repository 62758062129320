import { Module } from 'vuex';
import {
  BasicError, CreateDropResponse, Drop, DropFile, FullDropInfo,
} from '@/interfaces/models';
import { Api } from '@/services';
import { RootState } from '@/interfaces/store/shared';
import {
  AnyDropFile,
  ContextMenuState,
  DropState, FilePreviewState,
  UploadingDropFile,
} from '@/interfaces/store/drop';
import router from '@/router';
import axios, { AxiosError } from 'axios';

const module: Module<DropState, RootState> = {
  state: {
    error: {
      isError: false,
      message: null,
    },
    drop: null,
    dropFiles: null,
    accessToken: null,
    contextMenuState: null,
    previewState: { kind: 'hidden' },
  } as DropState,
  getters: {
    isDropActive(state): boolean {
      return state.drop !== null;
    },
  },
  mutations: {
    setDropError(state, message: string) {
      state.error.isError = true;
      state.error.message = message;
    },
    clearDropError(state) {
      state.error.isError = false;
      state.error.message = null;
    },
    clearDrop(state) {
      state.drop = null;
      state.dropFiles = null;
      state.accessToken = null;
    },
    setInitialDropState(state, {
      id,
      accessToken,
      createDate,
      expirationDate,
      ownerId,
    }: CreateDropResponse) {
      state.drop = {
        id,
        createDate,
        expirationDate,
        ownerId,
      };
      state.accessToken = accessToken;
      state.dropFiles = [];
    },
    setDrop(state, {
      id,
      createDate,
      expirationDate,
      ownerId,
      files,
    }: FullDropInfo) {
      const dropFiles: AnyDropFile[] = files
        .map((dropFile) => ({
          kind: 'dropFile',
          dropFile,
        }));
      state.drop = {
        id,
        createDate,
        expirationDate,
        ownerId,
      };
      state.dropFiles = dropFiles;
    },
    addUploadingFileToDrop(state, uploadingDropFile: UploadingDropFile) {
      if (state.dropFiles === null) return;

      state.dropFiles.push({
        kind: 'uploadingDropFile',
        dropFile: uploadingDropFile,
      });
    },
    removeUploadingFileFromDrop(state, filename: string) {
      if (state.dropFiles === null) return;

      const index = state.dropFiles.findIndex((f) => f.kind === 'uploadingDropFile'
        && f.dropFile.filename === filename);
      state.dropFiles.splice(index, 1);
    },
    updateDropFileProgress(
      state,
      {
        updateFile,
        progress,
      }: {
        updateFile: UploadingDropFile;
        progress: number;
      },
    ) {
      if (state.dropFiles === null) {
        return;
      }
      const replaceIndex = state.dropFiles
        .findIndex((f) => f.kind === 'uploadingDropFile' && f.dropFile.filename === updateFile.filename);
      if (replaceIndex === -1) {
        return;
      }
      const replaceItem = state.dropFiles[replaceIndex];

      if (replaceItem.kind === 'uploadingDropFile') {
        replaceItem.dropFile.progress = progress;
      }
    },
    completeDropFileUpload(state, {
      uploadingFile,
      dropFile,
    }: { uploadingFile: UploadingDropFile; dropFile: DropFile }) {
      if (state.dropFiles === null) {
        return;
      }
      const replaceIndex = state.dropFiles
        .findIndex((f) => f.kind === 'uploadingDropFile' && f.dropFile.filename === uploadingFile.filename);
      if (replaceIndex === -1) {
        return;
      }

      state.dropFiles.splice(replaceIndex, 1);
      state.dropFiles.push({
        kind: 'dropFile',
        dropFile,
      });
    },
    removeFile(state, fileId) {
      if (state.dropFiles === null) {
        return;
      }

      state.dropFiles.splice(state.dropFiles.findIndex((f) => f.kind === 'dropFile' && f.dropFile.id === fileId), 1);
    },
    openFileContextMenu(state, contextMenuState: ContextMenuState) {
      state.contextMenuState = contextMenuState;
    },
    closeFileContextMenu(state) {
      state.contextMenuState = null;
    },
    setFilePreviewState(state, previewState: FilePreviewState) {
      state.previewState = previewState;
    },
  },
  actions: {
    // Error
    setMessageForDropError(
      { commit },
      {
        error,
        defaultMsg,
      }: { error: Error; defaultMsg: string; },
    ) {
      if (axios.isAxiosError(error)) {
        if (error.response !== undefined && error.response.data.reason !== undefined) {
          commit('setDropError', error.response.data.reason);
        } else {
          commit('setDropError', defaultMsg);
        }
      } else {
        commit('setDropError', defaultMsg);
      }
    },
    // Drops
    async createDrop({
      commit,
      dispatch,
    }) {
      commit('clearDrop');
      try {
        const res = await Api.createDrop();
        const drop = res.data;
        commit('setInitialDropState', drop);
        await router.push(drop.id);
      } catch (error) {
        dispatch('setMessageForDropError', {
          error,
          defaultMsg: 'Unknown error occurred while creating drop',
        });
      }
    },
    async fetchDrop({
      commit,
      dispatch,
    }, dropId: string) {
      commit('clearDropError');
      try {
        const drop = await Api.fetchDropInfo(dropId);
        commit('setDrop', drop.data);
      } catch (error) {
        dispatch('setMessageForDropError', {
          error,
          defaultMsg: 'Unknown error occurred while loading drop',
        });
      }
    },
    async deleteDrop({
      commit,
      state,
      dispatch,
    }) {
      if (state.drop === null) {
        dispatch('pushErrorNotification', 'Failed to delete drop: drop is not loaded');
        return;
      }

      try {
        await Api.deleteDrop(state.drop.id, state.accessToken ?? undefined);

        await router.push('/');
        commit('clearDrop');
        dispatch('pushSuccessNotification', 'Drop successfully deleted');
      } catch (error) {

        if ((error as AxiosError | null)?.response?.data !== undefined) {
          const response = (error as AxiosError).response!.data as BasicError;
          dispatch('pushErrorNotification', `Failed to delete drop. ${response.reason}`);
        } else {
          dispatch('pushErrorNotification', 'Unexpected error occurred, failed to delete drop');
        }
      }
    },
    // Files
    async uploadFile({
      commit,
      state,
      dispatch,
      rootState,
    }, file: File) {
      if (state.drop === null || state.dropFiles === null) {
        dispatch('pushErrorNotification', 'Unexpected error occurred, failed to upload file');
        return;
      }
      if (state.dropFiles.some((f) => f.dropFile.filename === file.name)) {
        dispatch('pushErrorNotification', 'Failed to upload file, this drop already contains a file of the same name');
        return;
      }

      const dropFile = {
        filename: file.name,
        fileSize: file.size,
        dropId: state.drop.id,
        progress: 0,
      };
      commit('addUploadingFileToDrop', dropFile);

      try {
        const res = await Api.uploadFile(state.drop.id, file, (progressEvent) => {
          const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);

          commit('updateDropFileProgress', {
            updateFile: dropFile,
            progress,
          });
        }, state.accessToken ?? undefined);
        const uploadedDropFile = res.data as DropFile;

        commit('completeDropFileUpload', {
          uploadingFile: dropFile,
          dropFile: uploadedDropFile,
        });
      } catch (e) {
        dispatch('pushErrorNotification', 'Unexpected error occurred, failed to upload file');
        commit('removeUploadingFileFromDrop');
      }
    },
    async uploadFiles({ dispatch }, files: File[]) {
      Array.prototype.forEach.call(files, (f) => dispatch('uploadFile', f));
    },
    async showFilePreview({
      state,
      dispatch,
      commit,
    }, fileId: number) {
      if (state.drop === null) return;

      commit('setFilePreviewState', { kind: 'loading' });
      try {
        const res = await Api.fetchDropFilePreview(state.drop.id, fileId);
        commit('setFilePreviewState', { kind: 'loaded', filename: res.data.filename, content: res.data.content });
      } catch (e) {
        if ((e as AxiosError | null)?.response?.data !== undefined) {
          const response = (e as AxiosError).response!.data as BasicError;
          commit('setFilePreviewState', {
            kind: 'error',
            message: response.reason,
          });
        } else {
          dispatch('pushErrorNotification', 'Unexpected error occurred. Failed to load file preview');
          commit('setFilePreviewState', {
            kind: 'error',
            message: 'Unexpected error occurred while loading preview',
          });
        }
      }
    },
    hideFilePreview({ commit }) {
      commit('setFilePreviewState', { kind: 'hidden' });
    },
    async deleteFile({
      commit,
      state,
      dispatch,
    }, fileId: number) {
      if (state.drop === null || state.dropFiles == null) {
        dispatch('pushErrorNotification', 'Unexpected error occurred, failed to delete file');
        return;
      }

      try {
        await Api.deleteDropFile(state.drop.id, fileId, state.accessToken ?? undefined);

        commit('removeFile', fileId);

        if (state.dropFiles.length === 0) {
          await router.push('/');
          commit('clearDrop');
        }
      } catch (error) {
        if ((error as AxiosError | null)?.response?.data !== undefined) {
          const response = (error as AxiosError).response!.data as BasicError;
          dispatch('pushErrorNotification', `Failed to delete file. ${response.reason}`);
        } else {
          dispatch('pushErrorNotification', 'Unexpected error occurred, failed to delete file');
        }
      }
    },
  },
};

export default module;
