<template>
  <section v-if="!$store.getters.isLoggedIn" class="flex-grow max-w-[72rem]">
    <div class="flex flex-col w-full items-center gap-y-3 mt-16">
      <i class="fas fa-exclamation-triangle fa-3x text-white"></i>
      <h6 class="text-gray-200">You have to sign in to access this page</h6>
    </div>
  </section>
  <account-settings-section v-else-if="currentRouteName === 'accountSettings'" />
  <my-drops-section v-else-if="currentRouteName === 'myDrops'" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import AccountSettingsSection
  from '@/ui/components/profile/account_settings/account_settings_section.vue';
import MyDropsSection from '@/ui/components/profile/drops/my_drops.vue';

export default defineComponent({
  name: 'ProfileSectionContainer',
  components: { MyDropsSection, AccountSettingsSection },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  mounted() {
    if (!this.$store.getters.isLoggedIn) {
      this.$router.push('/');
    }
  },
});
</script>
